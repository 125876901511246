import { Injectable } from '@angular/core';
import {
    CanActivate,
    Router,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
} from '@angular/router';

import { LoginService } from '@intranet/modules/login';

class AuthBase implements CanActivate {
    constructor(private loginService: LoginService, private router: Router) {}

    canActivateChild(
        childRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean {
        return this.canActivate(childRoute, state);
    }

    roles: string[] = [];

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean {
        const url = state.url;
        return this.checkLogin(url);
    }

    checkLogin(url: string): boolean {
        if (this.loginService.checkAuth(this.roles)) {
            return true;
        }
        this.loginService.redirectUrl = url;
        this.router.navigate(['/login']);
        return false;
    }
}

@Injectable()
export class AuthGuard extends AuthBase {
    constructor(loginService: LoginService, router: Router) {
        super(loginService, router);
    }
}

@Injectable()
export class AuthGuardAdmin extends AuthBase {
    constructor(loginService: LoginService, router: Router) {
        super(loginService, router);
        this.roles = ['Admin'];
    }
}

@Injectable()
export class AuthGuardManager extends AuthBase {
    constructor(loginService: LoginService, router: Router) {
        super(loginService, router);
        this.roles = ['Manager'];
    }
}
@Injectable()
export class AuthGuardCalendar extends AuthBase {
    constructor(loginService: LoginService, router: Router) {
        super(loginService, router);
        this.roles = ['Calendar'];
    }
}
@Injectable()
export class AuthGuardFinance extends AuthBase {
    constructor(loginService: LoginService, router: Router) {
        super(loginService, router);
        this.roles = ['Finance'];
    }
}
