import { Route } from '@angular/router';
import { AppResolver } from './app.resolver';
import { AuthGuard } from './core/services/auth-guard.service';
import { LayoutComponent } from './layout/layout.component';

export const appRoutes: Route[] = [
    { path: '', pathMatch: 'full', redirectTo: 'home' },

    // Guests routes
    {
        path: '',
        //canActivate: [NoAuthGuard],
        //canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: '',
                loadChildren: () =>
                    import('src/app/modules/login/login.module').then(
                        (m) => m.LoginModule
                    ),
            },
        ],
    },

    // Auth routes
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'default',
        },
        resolve: {
            initialData: AppResolver,
        },
        children: [
            {
                path: '',
                loadChildren: () =>
                    import('src/app/modules/home/home.module').then(
                        (m) => m.HomeModule
                    ),
            },
            {
                path: '',
                loadChildren: () =>
                    import('src/app/modules/clients/clients.module').then(
                        (m) => m.ClientsModule
                    ),
            },
            {
                path: '',
                loadChildren: () =>
                    import('src/app/modules/jobs/jobs.module').then(
                        (m) => m.JobsModule
                    ),
            },
            {
                path: '',
                loadChildren: () =>
                    import('src/app/modules/actions/action.module').then(
                        (m) => m.ActionModule
                    ),
            },
            {
                path: '',
                loadChildren: () =>
                    import('src/app/modules/finance/finance.module').then(
                        (m) => m.FinanceModule
                    ),
            },
            {
                path: '',
                loadChildren: () =>
                    import('src/app/modules/users/users.module').then(
                        (m) => m.UsersModule
                    ),
            },
            {
                path: '',
                loadChildren: () =>
                    import('src/app/modules/admin/admin.module').then(
                        (m) => m.AdminModule
                    ),
            },
            {
                path: '',
                loadChildren: () =>
                    import('src/app/modules/report/report.module').then(
                        (m) => m.ReportModule
                    )
            },
            {
                path: '',
                loadChildren: () =>
                    import('src/app/modules/holidays/holiday.module').then(
                        (m) => m.HolidayModule
                    )
            }
        ],
    },
];
