import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Client } from '../../client.types';

@Component({
    selector: 'client-listing-table',
    templateUrl: './listing-table.component.html',
    styleUrls: ['./listing-table.component.scss'],
})
export class ClientListingTableComponent {
    @Input() clients: Client[];
    @Input() isPinned: boolean = false;
    @Input() hideMenu = false;
    @Input() canPin: boolean = true;
    @Output() pin: EventEmitter<Client> = new EventEmitter<Client>();
    @Output() unpin: EventEmitter<Client> = new EventEmitter<Client>();
    @Output() selectClient: EventEmitter<number> = new EventEmitter<number>();
    @Output() selectGroup: EventEmitter<number> = new EventEmitter<number>();
    groupsExpanded = {};

    onSelectClient(clientId: number) {
        this.selectClient.emit(clientId);
    }

    onSelectGroup(clientId: number) {
        this.selectGroup.emit(clientId);
    }

    onGroupToggle(groupId: number) {
        this.groupsExpanded[groupId]
            ? delete this.groupsExpanded[groupId]
            : (this.groupsExpanded[groupId] = true);
    }

    onPin(client: Client) {
        this.pin.emit(client);
    }

    onUnPin(client: Client) {
        this.unpin.emit(client);
    }
}
