import {
    Component,
    Input,
    OnInit,
    Output,
    EventEmitter,
    Renderer2,
    ViewChild,
    ElementRef,
    forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import * as moment from 'moment';
import { Observable, Subscription, timer } from 'rxjs';

interface DateItem {
    date: moment.Moment;
    value: number;
    isCurrentMonth: boolean;
    isDisabled: boolean;
}

interface MonthItem {
    month: number;
    monthName: string;
}

interface DateRow {
    days: DateItem[];
}

@Component({
    selector: 'daypicker',
    templateUrl: './daypicker.component.html',
    styleUrls: ['./daypicker.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DayPicker),
            multi: true,
        },
    ],
})
export class DayPicker implements OnInit, ControlValueAccessor {
    dateChanged = (_: any) => {};

    registerOnChange(fn: any) {
        this.dateChanged = fn;
    }

    registerOnTouched(fn: any) {}

    writeValue(value: any) {
        if (value && moment(value).isValid()) {
            this.date = value.toString();
            this.setup(this.parsedDate);
        }
    }

    ngOnInit(): void {
        if (this.date) {
            this.setup(this.parsedDate);
        }
        if (this.focusOnCreate) {
            this.focusField();
        }
        if (!this.alwaysShow) {
            this.showPicker = false;
        }
    }

    dateGrid: DateItem[][];
    monthName: string;
    yearName: string;
    yearBlockName: string;

    _date: string;
    //date: string;  // the input date field must be in ISO format
    get date(): string {
        return this._date;
    }
    set date(value: string) {
        this._date = value;
        //this.dateChanged(this._date);
    }
    dateForEdit: string; // This is the field in DD/MM/YYYY format used by the edit box

    @Input() disallowPast: boolean;
    @Input() format: string = 'DD/MM/YYYY';
    _lowerLimit: string | moment.Moment = '';
    _upperLimit: string | moment.Moment = '';
    @Input() set lowerLimit(value: string | moment.Moment) {
        this._lowerLimit = value;
        if (this.dateForEdit) {
            var today = this.makemoment(this.dateForEdit);
            this.refreshGrid(today);
        }
    }
    get lowerLimit(): string | moment.Moment {
        return this._lowerLimit;
    }
    @Input() set upperLimit(value: string | moment.Moment) {
        this._upperLimit = value;
        if (this.dateForEdit) {
            var today = this.makemoment(this.dateForEdit);
            this.refreshGrid(today);
        }
    }
    get upperLimit(): string | moment.Moment {
        return this._upperLimit;
    }
    @Input() focusOnCreate: boolean = true;
    @Input() alwaysShow: boolean = false;
    showPicker: boolean = true;
    @Input() textFieldClasses: string = '';
    @Input() default: any = { days: 7 };
    @Input() baseDay: string = 'Mon';

    monthGrid: any | null;
    yearGrid: number[][] | null;

    @Output() onResult = new EventEmitter<string>();
    @ViewChild('inputField') inputField: ElementRef;
    baseDate: moment.Moment;

    // states
    dayState = true;
    monthState = false;
    yearState = false;

    switchToMonths() {
        this.dayState = false;
        this.monthState = true;
        this.yearState = false;
        this.focusField();
    }

    switchToYears() {
        this.dayState = false;
        this.monthState = false;
        this.yearState = true;
        this.focusField();
    }

    switchToDays() {
        this.dayState = true;
        this.monthState = false;
        this.yearState = false;
        this.focusField();
    }

    chooseYear(year: number) {
        this.setup(this.baseDate.year(year));
        this.switchToMonths();
    }

    chooseMonth(month: number) {
        this.setup(this.baseDate.month(month));
        this.switchToDays();
    }

    focusField() {
        //this.renderer.invokeElementMethod(this.inputField.nativeElement, 'focus', []);
        this.inputField?.nativeElement.focus();
    }

    constructor(private renderer: Renderer2) {}

    setup(today: moment.Moment) {
        this.dateForEdit = today.format(this.format);
        this.refreshGrid(today);
    }

    dayLabels = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

    get dayLabelsWithBaseDay(): string[] {
        var ind = this.getBaseDayIndex();
        return this.dayLabels.slice(ind).concat(this.dayLabels.slice(0, ind));
    }

    getBaseDayIndex(): number {
        var ind = this.dayLabels.findIndex((d) => d === this.baseDay);
        if (ind < 0) {
            ind = 0;
        }
        return ind;
    }

    refreshGrid(today: moment.Moment) {
        //console.log(this.makemoment(this.upperLimit));
        let actualToday = this.makemoment();
        today.hour(0).minute(0).second(0).millisecond(0);
        this.monthName = today.format('MMMM YYYY');
        this.yearName = today.format('YYYY');
        let first = today.clone().date(1);
        this.baseDate = first.clone();
        let firstnext = first.clone().add(1, 'month');
        let ind = this.getBaseDayIndex() + 1;
        while (first.day() != ind) {
            first.add(-1, 'day');
        }
        var grid = [];
        while (firstnext.isAfter(first)) {
            var row: DateItem[] = [];
            grid.push(row);
            for (let j = 0; j < 7; j++) {
                row.push({
                    date: first.clone(),
                    value: first.date(),
                    isCurrentMonth: first.month() === this.baseDate.month(),
                    isDisabled: this.isDisallowed(first),
                });
                first.add(1, 'day');
            }
        }
        this.dateGrid = grid;
        //console.log(grid);
        if (!this.monthGrid) {
            this.monthGrid = [
                [
                    { month: 0, monthName: 'January' },
                    { month: 1, monthName: 'February' },
                    { month: 2, monthName: 'March' },
                ],
                [
                    { month: 3, monthName: 'April' },
                    { month: 4, monthName: 'May' },
                    { month: 5, monthName: 'June' },
                ],
                [
                    { month: 6, monthName: 'July' },
                    { month: 7, monthName: 'August' },
                    { month: 8, monthName: 'September' },
                ],
                [
                    { month: 9, monthName: 'October' },
                    { month: 10, monthName: 'November' },
                    { month: 11, monthName: 'December' },
                ],
            ];
        }
        let yearStart = today.year() - 12;
        let yearEnd = today.year() + 12;
        this.yearBlockName = `${yearStart}-${yearEnd}`;
        this.yearGrid = [];
        for (let yr = yearStart; yr <= yearEnd; yr += 5) {
            let row: number[] = [];
            this.yearGrid.push(row);
            for (let y = yr; y < yr + 5; y++) {
                row.push(y);
            }
        }
    }

    isDisallowed(date: moment.Moment): boolean {
        if (this.disallowPast) {
            var today = moment().startOf('day');
            if (date.isBefore(today)) {
                return true;
            }
        }
        if (this.lowerLimit) {
            var lower = this.makemoment(this.lowerLimit);
            if (date.isBefore(lower)) {
                return true;
            }
        }
        if (this.upperLimit) {
            var upper = this.makemoment(this.upperLimit);
            //console.log(upper.format('DD/MM/YYYY'), date.format('DD/MM/YYYY'));
            if (date.isAfter(upper)) {
                //console.log(this.upperLimit, date, upper);
                return true;
            }
        }
        return false;
    }

    prevMonth() {
        this.setup(this.baseDate.add(-1, 'month'));
    }
    nextMonth() {
        this.setup(this.baseDate.add(1, 'month'));
    }

    prevYear() {
        this.setup(this.baseDate.add(-1, 'year'));
    }
    nextYear() {
        this.setup(this.baseDate.add(1, 'year'));
    }
    prevBlock() {
        this.setup(this.baseDate.add(-25, 'year'));
    }
    nextBlock() {
        this.setup(this.baseDate.add(25, 'year'));
    }
    timerSubscription: Subscription | null;

    focusButton(evt: any) {
        this.cancelBlurTimer();
        if ((!this.date || !moment(this.date).isValid()) && this.default) {
            var defmoment = moment().add(this.default);
            if (this.lowerLimit) {
                var lower = this.makemoment(this.lowerLimit);
                if (defmoment.isBefore(lower)) {
                    defmoment = lower.clone();
                }
            }
            if (this.upperLimit) {
                var upper = this.makemoment(this.upperLimit);
                if (defmoment.isAfter(upper)) {
                    defmoment = upper.clone();
                }
            }
            this.setup(defmoment);
            this.date = defmoment.format();
        }
        if (!this.alwaysShow) {
            this.showPicker = true;
        }
    }

    cancelBlurTimer() {
        if (this.timerSubscription) {
            this.timerSubscription.unsubscribe();
            this.timerSubscription = null;
        }
    }

    blurButton(evt: any) {
        if (!this.timerSubscription) {
            this.timerSubscription = timer(175).subscribe((i) => {
                if (this.timerSubscription) {
                    this.timerSubscription.unsubscribe();
                }
                // Do something with the result
                var editMoment = this.makemoment(this.dateForEdit);
                if (editMoment.isValid()) {
                    this.date = editMoment.format();
                    this.onResult.emit(this.date);
                }
                if (!this.alwaysShow) {
                    this.showPicker = false;
                }
            });
        }
    }

    pickDay(item: DateItem) {
        this.returnResult(item.date);
    }

    cancelDate() {
        this.returnEmpty();
    }

    returnResult(date: moment.Moment) {
        this.dateForEdit = date.format(this.format);
        this.date = date.format();
        this.dateChanged(this._date);
        this.onResult.emit(this.date);
        if (!this.alwaysShow) {
            this.showPicker = false;
        }
    }

    isCurrentSelection(item: DateItem): boolean {
        return item.date.format() === this.date;
    }

    returnEmpty() {
        this.dateForEdit = '';
        this.date = '';
        this.dateChanged(this._date);
        this.onResult.emit(this.date);
        if (!this.alwaysShow) {
            this.showPicker = false;
        }
    }

    get parsedDate(): moment.Moment {
        return moment(this.date);
    }

    makemoment(date?: string | moment.Moment, format?: string): moment.Moment {
        if (date) {
            if (typeof date === 'string') {
                //console.log("converting " + date);
                var fmat = format;
                if (!fmat) {
                    fmat = this.format;
                }
                if (!fmat) {
                    fmat = 'DD/MM/YYYY';
                }
                return moment(date, fmat).startOf('day');
            } else {
                //console.log("cloning date", date);
                return date.clone();
            }
        } else {
            return moment().startOf('day');
        }
    }

    tomorrow() {
        this.returnResult(this.makemoment().add(1, 'day'));
    }
    oneWeek() {
        this.returnResult(this.makemoment().add(1, 'week'));
    }
    nextMonday() {
        var today = this.makemoment();
        var day = today.day();
        this.returnResult(today.add((8 - day) % 7, 'day'));
    }
}
